import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from "axios";

import 'vue-multiselect/dist/vue-multiselect.css';

import Multiselect from 'vue-multiselect';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCaretDown, faCirclePlay, faGrip, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
library.add([faCaretDown, faCirclePlay, faGrip, faXmark]);
library.add(faFacebook);

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`

import bootstrap from 'bootstrap/dist/js/bootstrap';

const app = createApp(App)
    app.use(store)
    app.use(router)
    app.use(bootstrap)
    app.component('font-awesome-icon', FontAwesomeIcon)
    app.component('multiselect', Multiselect)
    app.mount('#app')
