<template>
  <div>
    <header class="landing-page">
      <video autoplay="autoplay" loop="loop" muted="muted">
        <source src="./../assets/videos/video.webm" type="video/webm" />
        <source src="./../assets/videos/video.mp4" type="video/mp4" />
        <!-- <source src="./../assets/videos/video.html" type="video/ogv" /> -->
      </video>
      <div class="container hero-header">
        <h1>Natural Hog Raising</h1>
        <p>Feedpro is the first and leading natural plant and Probiotic-based hog feed in the Philippines that effectively reduces piggery odor and produces safe and healthy meat.</p>
        <p class="btn-paragraph">
          <router-link :to="{ name: 'inquiry' }" class="button-primary" role="button">FIND AN OUTLET NEAR YOU</router-link>
        </p>
      </div>
      <div class="container">
        <div class="mouse-scroll-probbit">
          <div class="moving-mouse-holder">
            <p class="text scroll-text">Scroll down<br>to discover more</p>
            <div class="mouse">
              <div class="mouse-button">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid bg-3">
        <div class="div-subheader header-bg3">
          <h2 class="text-white">Natural Hog Raising</h2>
          <div class="subheader-white">
            <p>Feedpro is the first and leading natural plant and Probiotic-based hog feed in the Philippines that effectively reduces piggery odor and produces safe and healthy meat.</p>
          </div>
          <div class="text-center div-paragraph">
            <router-link :to="{ name: 'inquiry' }" class="button-secondary" role="button">FIND AN OUTLET NEAR YOU</router-link>
          </div>
        </div>
      </div>
    </header>

    <section class="div-panel">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center div-header3">
              <h2>Feedpro Advantage</h2>
              <h3 class="roboto400 font28">Uncompromised Growth Rate and Profitability</h3>
              <div class="subheader">
                <p>Being natural doesn't mean lower yields. Tried and tested for more than a decade, Feedpro's formula delivers equal or even better Feed Conversion Ratio and higher Average Daily Growth rates versus other brands.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row" style="padding-top: 30px;">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="text-center div-paragraph">
              <img :src="`${state.root_url}/images/natural-hog-feed.png`" class="img-feedpro" />
              <h4>Natural Hog Feed</h4>
              <p>Completely free of antibiotics, synthetic chemicals, and artiﬁcial growth hormones make for safe and natural meat.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="text-center div-paragraph">
              <img :src="`${state.root_url}/images/probiotic-tech.png`" class="img-feedpro" />
              <h4>Probiotics Technology</h4>
              <p>Improved growth performance brought about by better digestion, better nutrient absorption, and better gut health.</p>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="text-center div-paragraph">
              <img :src="`${state.root_url}/images/fast-feed.png`" class="img-feedpro" />
              <h4>Fast Feed Fermentation</h4>
              <p>Enjoy bigger savings with the only commercial feed that you can ferment by soaking in water and feed in liquid form.</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center div-paragraph">
              <p class="btn-paragraph">
                <router-link :to="{ name: 'feeding' }" class="button-primary" role="button">LEARN ABOUT OUR FEED</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-green div-panel">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="bwa-center div-subheader reverse-div2 feedpro-flex" style="height: 100%;">
              <div>
                <h2>Babuyang Walang Amoy</h2>
                <p>With Feedpro and correct management practices, you can reduce or eradicate the unpleasant odor in your conventional cemented or rice hull substrate bedding piggery.</p>
                <p class="btn-paragraph">
                  <router-link :to="{ name: 'piggery' }" class="button-secondary">SET UP AN ODORLESS PIGGERY</router-link>
                </p>
              </div>
            </div>
            <div class="div-image2 reverse-image">
              <img :src="`${state.root_url}/images/babuyang-walang-amoy.png`" class="img-bwa" />
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 text-center">
            <div class="div-image2 reverse-image2">
              <img :src="`${state.root_url}/images/babuyang-walang-amoy.png`" class="img-bwa" />
            </div>
            <div class="bwa-center div-subheader reverse-div">
              <h2>Babuyang Walang Amoy</h2>
              <p>With Feedpro and correct management practices, you can reduce or eradicate the unpleasant odor in your conventional cemented or rice hull substrate bedding piggery.</p>
              <p class="btn-paragraph padtop6">
                <router-link :to="{ name: 'piggery' }" class="button-secondary">SET UP AN ODORLESS PIGGERY</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="div-panel">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12 text-center div-flex">
            <div class="div-image">
              <img :src="`${state.root_url}/images/natural-meat.png`" class="img-natmeat" />
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 div-flex">
            <div class="div-subheader">
              <h2>
                Healthy Meat,<br />
                Grown the Natural Way
              </h2>
              <p>Delicious, safe, and natural pork completely free of antibiotic residues, synthetic chemicals, and artiﬁcial growth hormones</p>
              <!-- <p class="btn-paragraph padtop6">
                <a href="{{ url('natural-meat-shops') }}" class="button-primary">NATURAL MEAT SHOPS</a>
              </p> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-green div-panel">
      <div class="container">
        <div class="row row-panel5">
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="div-header2">
              <h3 class="roboto700">Attend a Seminar</h3>
              <div class="ch-100">
                <p>Babuyang Walang Amoy seminars are regularly conducted in barangays and municipalities nationwide. Stay updated with the latest seminar venues and schedules by connecting with us on Facebook.</p>
              </div>
              <p class="btn-paragraph">
                <a href="https://www.facebook.com/feedprofeeds/" class="button-secondary" target="_blank">&nbsp;&nbsp;&nbsp;CONNECT WITH US&nbsp;&nbsp;&nbsp;</a>
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="div-header2">
              <h3 class="roboto700">Watch Feedpro TV</h3>
              <div class="ch-100">
                <p>Watch online and learn about natural hog raising and Babuyang Walang Amoy though the episodes and How-to videos posted on our YouTube channel.</p>
              </div>
              <p class="btn-paragraph">
                <a href="https://www.youtube.com/channel/UCHYbopxCtlWm4hg4RgB4Yyg" class="button-secondary" target="_blank">WATCH VIDEOS</a>
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-12 col-sm-12">
            <div class="div-header2">
              <h3 class="roboto700">Visit an Info Center</h3>
              <div class="ch-100">
                <p>Locate a Feedpro Babuyang Walang Amoy information center nearest you. Our info centers feature a natural piggery, training hall, and organic vegetable garden.</p>
              </div>
              <p class="btn-paragraph">
                <router-link :to="{ name: 'inquiry', query: { section: 'info-center' } }" class="button-secondary">VISIT INFO CENTER</router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="div-panel">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-6 col-sm-12 d-flex align-items-center">
            <div class="div-image">
              <img :src="`${state.root_url}/images/pronatural-corp.png`" class="img-pronat" />
            </div>
          </div>
          <div class="col-lg-7 col-md-6 col-sm-12">
            <div class="div-subheader feedpro-flex" style="height: auto; padding-left: 40px;">
              <div>
                <h1>Quality from the HEART</h1>
                <p>Pronatural Feed Corporation, the company behind Feedpro, is dedicated to producing consistent quality feed. Our manufacturing facilities conform to ISO standards ensuring only the best for our customers.</p>
                <p class="btn-paragraph padtop6">
                  <router-link :to="{ name: 'about' }" class="button-primary">MORE ABOUT OUR COMPANY</router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { reactive } from 'vue';

export default {
  setup() {
    const state = reactive({
      root_url: process.env.VUE_APP_ENDPOINT
    });

    return {
      state
    }
  }
}
</script>

<style scoped>
  .scroll-text {
    position: absolute;
    left: -150px;
    width: 140px;
  }
</style>
