import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/ProductView.vue')
  },
  {
    path: '/feeding-program',
    name: 'feeding',
    component: () => import('@/views/FeedingProgram.vue')
  },
  {
    path: '/piggery',
    name: 'piggery',
    component: () => import('@/views/PiggeryView.vue')
  },
  {
    path: '/proavian',
    name: 'proavian',
    component: () => import('@/views/ProavianView.vue')
  },
  {
    path: '/probbit',
    name: 'probbit',
    component: () => import('@/views/ProbbitView.vue')
  },
  {
    path: '/company',
    name: 'about',
    component: () => import('@/views/AboutView.vue')
  },
  {
    path: '/get-in-touch',
    name: 'inquiry',
    component: () => import('@/views/InquiryView.vue')
  },
  {
    path: '/thankyou',
    name: 'thankyou',
    component: () => import('@/views/ThankyouView.vue')
  },
  {
    path: '/company/directory',
    name: 'directory',
    component: () => import('@/views/DirectoryView.vue')
  },
  {
    path: '/company/logos',
    name: 'logos',
    component: () => import('@/views/LogoView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
