<template>
  <div class="global-header on-home shadow-on">
    <div class="container top">
      <div class="feedpro-row">
        <div class="global-logo">
          <a href="/" data-ga-navigation-location="header">
            <span>Feedpro</span>
            <img src="./assets/images/logo.svg" style="width: 70%;" />
          </a>
        </div>

        <nav class="top-level-nav">
          <ul>
            <li>
              <a href="#" data-ga-navigation-location="header">HOGS</a><span class="arrow"></span>
              <div class="sub-nav" data-orig-height="337" style="overflow-y: visible;">
                <div class="sub-nav-col center">
                  <a href="/product" class="sub-nav-box" data-ga-navigation-location="header">
                    <div class="plans" :style="`background-image: url('${state.root_url}/images/hogs_1.png');`"></div>
                    <div class="labels">
                      <h4>PRODUCT DETAILS</h4>
                    </div>
                    <p>Learn about Feedpro</p>
                  </a>
                  <a href="/feeding-program" class="sub-nav-box" data-ga-navigation-location="header">
                    <div class="plans" :style="`background-image: url('${state.root_url}/images/hogs_2.png');`"></div>
                    <div class="labels">
                      <h4>FEEDING PROGRAM</h4>
                    </div>
                    <p>How much to feed</p>
                  </a>
                  <a href="/piggery" class="sub-nav-box" data-ga-navigation-location="header">
                    <div class="plans" :style="`background-image: url('${state.root_url}/images/hogs_4.png');`"></div>
                    <div class="labels">
                      <h4>PIGGERY</h4>
                    </div>
                    <p>Babuyang Walang Amoy</p>
                  </a>
                  <a href="http://www.youtube.com/feedprotv" class="sub-nav-box" data-ga-navigation-location="header" target="_blank">
                    <div class="plans" :style="`background-image: url('${state.root_url}/images/hogs_3.png');`"></div>
                    <div class="labels">
                      <h4>FEATURED VIDEOS</h4>
                    </div>
                    <p>Watch and learn</p>
                  </a>
                </div>
              </div>
            </li>
            <li>
              <a href="#" data-ga-navigation-location="header">POULTRY</a><span class="arrow"></span>
              <div class="sub-nav" data-orig-height="196" style="overflow-y: visible;">
                <div class="sub-nav-col center">
                  <a href="/proavian" class="sub-nav-box" data-ga-navigation-location="header">
                    <div class="plans basic" :style="`background-image: url('${state.root_url}/images/proavian_1.png');`"></div>
                    <div class="labels">
                      <h4>PRODUCT DETAILS</h4>
                    </div>
                    <p>Learn about Proavian</p>
                  </a>
                  <a href="https://youtube.com/playlist?list=PLl7pQqMM28bofbyeKFqNnbDb_-MiNRNQK" class="sub-nav-box" data-ga-navigation-location="header" target="_blank">
                    <div class="plans premium" :style="`background-image: url('${state.root_url}/images/proavian_2.png');`"></div>
                    <div class="labels">
                      <h4>FEATURED VIDEOS</h4>
                    </div>
                    <p>Watch and learn</p>
                  </a>
                </div>
              </div>
            </li>
            <li>
              <a href="#" data-ga-navigation-location="header">RABBIT</a><span class="arrow"></span>
              <div class="sub-nav" data-orig-height="196" style="overflow-y: visible;">
                <div class="sub-nav-col center">
                  <a href="/probbit" class="sub-nav-box" data-ga-navigation-location="header">
                    <div class="plans basic" :style="`background-image: url('${state.root_url}/images/probbit_1.png');`"></div>
                    <div class="labels">
                      <h4>PRODUCT DETAILS</h4>
                    </div>
                    <p>Learn about Probbit</p>
                  </a>
                  <a href="https://youtube.com/playlist?list=PLl7pQqMM28bohSP9MnSqersq6B2GRlvRG" class="sub-nav-box" data-ga-navigation-location="header" target="_blank">
                    <div class="plans premium" :style="`background-image: url('${state.root_url}/images/probbit_2.png');`"></div>
                    <div class="labels">
                      <h4>FEATURED VIDEOS</h4>
                    </div>
                    <p>Watch and learn</p>
                  </a>
                </div>
              </div>
            </li>
            <li><a href="/company" data-ga-navigation-location="header">About Us</a></li>
          </ul>
        </nav>

        <nav class="utility-nav">
          <ul>
            <li>
              <a href="/get-in-touch" class="button-outline" data-ga-navigation-location="header">INQUIRE</a>
            </li>
            <li>
              <a href="https://www.facebook.com/feedprofeeds/" data-ga-navigation-location="header">
                <img src="./assets/images/facebook.svg" style="width: 35px;" />
              </a>
            </li>
            <li>
              <a href="http://www.youtube.com/feedprotv" data-ga-navigation-location="header">
                <img src="./assets/images/youtube.svg" style="width: 35px;" />
              </a>
            </li>
          </ul>
        </nav>

        <a class="drawer-nav-open-btn" href="#" @click="openMenu">
          <img src="./assets/images/nav-open-tablet.svg" />
        </a>
      </div>
    </div>

    <div class="drawer-nav" v-if="isMenuOpen">
      <div class="row drawer-header">
        <div class="global-logo" style="float: left;">
          <a href="#" data-ga-navigation-location="header">
            <span>Feedpro</span>
            <img src="./assets/images/logo_1.png" />
          </a>
        </div>

        <a class="drawer-nav-close drawer-nav-close-btn" href="#" @click="closeMenu" style="position: absolute;right: 35px;top: 35px;">
          <img src="./assets/images/nav-close-tablet.svg" />
        </a>
      </div>
      <div class="feedpro-row">
        <nav class="mobile-nav">
          <ul style="position: relative; left: -30px;">
            <li class="mobile-sub-nav-expandable">
              <div class="nav-label" data-ga-navigation-location="header" @click="mobileMenu('hogs')">HOGS<span class="arrow"></span></div>
              <div class="sub-nav" data-orig-height="0" style="overflow-y: visible;" :class="{ 'show' : state.isHogsOpen }">
                <div class="sub-nav-col center">
                  <a href="/product" class="sub-nav-box" data-ga-navigation-location="header">
                    <div class="plans" :style="`background-image: url('${state.root_url}/images/hogs_1.png');`"></div>
                    <div class="labels">
                      <h4>PRODUCT DETAILS</h4>
                    </div>
                    <p>Learn about Feedpro</p>
                  </a>
                  <a href="/feeding-program" class="sub-nav-box" data-ga-navigation-location="header">
                    <div class="plans" :style="`background-image: url('${state.root_url}/images/hogs_2.png');`"></div>
                    <div class="labels">
                      <h4>FEEDING PROGRAM</h4>
                    </div>
                    <p>How much to feed</p>
                  </a>
                  <a href="/piggery" class="sub-nav-box" data-ga-navigation-location="header">
                    <div class="plans" :style="`background-image: url('${state.root_url}/images/hogs_4.png');`"></div>
                    <div class="labels">
                      <h4>PIGGERY</h4>
                    </div>
                    <p>Babuyang Walang Amoy</p>
                  </a>
                  <a href="http://www.youtube.com/feedprotv" class="sub-nav-box" data-ga-navigation-location="header">
                    <div class="plans" :style="`background-image: url('${state.root_url}/images/hogs_3.png');`"></div>
                    <div class="labels">
                      <h4>FEATURED VIDEOS</h4>
                    </div>
                    <p>Watch and learn</p>
                  </a>
                </div>
              </div>
            </li>
            <li class="mobile-sub-nav-expandable">
              <div class="nav-label" data-ga-navigation-location="header" @click="mobileMenu('poultry')">POULTRY<span class="arrow"></span></div>
              <div class="sub-nav" data-orig-height="0" style="overflow-y: visible;" :class="{ 'show' : state.isPoultryOpen }">
                <div class="sub-nav-col center">
                  <a href="/proavian" class="sub-nav-box" data-ga-navigation-location="header">
                    <div class="plans basic" :style="`background-image: url('${state.root_url}/images/proavian_1.png');`"></div>
                    <div class="labels">
                      <h4>PRODUCT DETAILS</h4>
                    </div>
                    <p>Learn about Proavian</p>
                  </a>
                  <a href="https://youtube.com/playlist?list=PLl7pQqMM28bofbyeKFqNnbDb_-MiNRNQK" class="sub-nav-box" data-ga-navigation-location="header">
                    <div class="plans premium" :style="`background-image: url('${state.root_url}/images/proavian_2.png');`"></div>
                    <div class="labels">
                      <h4>FEATURED VIDEOS</h4>
                    </div>
                    <p>Watch and learn</p>
                  </a>
                </div>
              </div>
            </li>
            <li class="mobile-sub-nav-expandable">
              <div class="nav-label" data-ga-navigation-location="header" @click="mobileMenu('rabbit')">RABBIT<span class="arrow"></span></div>
              <div class="sub-nav" data-orig-height="0" style="overflow-y: visible;" :class="{ 'show' : state.isRabbitOpen }">
                <div class="sub-nav-col center">
                  <a href="/probbit" class="sub-nav-box" data-ga-navigation-location="header">
                    <div class="plans basic" :style="`background-image: url('${state.root_url}/images/probbit_1.png');`"></div>
                    <div class="labels">
                      <h4>PRODUCT DETAILS</h4>
                    </div>
                    <p>Learn about Proavian</p>
                  </a>
                  <a href="https://youtube.com/playlist?list=PLl7pQqMM28bohSP9MnSqersq6B2GRlvRG" class="sub-nav-box" data-ga-navigation-location="header">
                    <div class="plans premium" :style="`background-image: url('${state.root_url}/images/probbit_2.png');`"></div>
                    <div class="labels">
                      <h4>FEATURED VIDEOS</h4>
                    </div>
                    <p>Watch and learn</p>
                  </a>
                </div>
              </div>
            </li>
            <li><a href="/company" class="nav-label" data-ga-navigation-location="header">About Us</a></li>
          </ul>
        </nav>
        <div class="app-badges">
          <a href="/get-in-touch" class="button-outline" data-ga-navigation-location="header">
            INQUIRE
          </a>
        </div>
      </div>
    </div>
    <div class="drawer-nav-close drawer-nav-mask"></div>
  </div>
  <router-view/>
  <section class="bg-green">
    <div class="container">
      <footer>
        <div class="row pt-30" style="width: 100%;">
          <div class="col-lg-3 col-md-3 col-sm-12">
            <h5 class="footer-h5">MAIN OFFICE ADDRESS</h5>
            <p>
              Pronatural Feed Corporation<br>
              7th floor, Richwell Center,<br>
              102 Timog Avenue<br>
              Brgy. Sacred Heart<br>
              Quezon City Philippines
            </p>
            <h5 class="footer-top">TELEPHONE NUMBERS</h5>
            <p>
              +63 2 8376 2293<br>
              +63 2 8375 1560 local 108<br>
              +63 917 815 2020
            </p>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12 footer-div">
            <h5 class="footer-h5"><a href="/product">FEEDPRO HOG FEED</a></h5>
            <p>
              <a href="/product">Feedpro Product Details</a><br>
              <a href="/feeding-program">Feeding Program</a>
              <ul style="list-style:none">
                <li><a href="https://pronatural-storage.s3.ap-southeast-1.amazonaws.com/feedpro/downloadable/Super_Premium_Program.pdf?" style="color: #fff" target="_blank">Super Premium</a></li>
                <li><a href="https://pronatural-storage.s3.ap-southeast-1.amazonaws.com/feedpro/downloadable/Premium_Program.pdf" style="color: #fff" target="_blank">Premium</a></li>
                <li><a href="https://pronatural-storage.s3.ap-southeast-1.amazonaws.com/feedpro/downloadable/Ecopro_Program.pdf" style="color: #fff" target="_blank">Ecopro</a></li>
                <li><a href="https://pronatural-storage.s3.ap-southeast-1.amazonaws.com/feedpro/downloadable/Breeder_Program.pdf" style="color: #fff" target="_blank">Breeder</a></li>
              </ul>
            </p>
            <p>
              <a href="/piggery">Piggery</a><br>
              <a href="https://www.youtube.com/channel/UCHYbopxCtlWm4hg4RgB4Yyg" target="_blank">Feedpro Videos</a>
            </p>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12 footer-div">
            <h5 class="footer-h5">PROAVIAN POULTRY FEED</h5>
            <p>
              <a href="/proavian">Proavian Product Details</a><br>
              <a href="https://pronatural-storage.s3.ap-southeast-1.amazonaws.com/feedpro/downloadable/Proavian_Brochure.pdf" target="_blank">Proavian Feeding Program</a><br>
              <a href="https://youtube.com/playlist?list=PLl7pQqMM28bofbyeKFqNnbDb_-MiNRNQK">Proavian Videos</a>
            </p>
            <h5 class="footer-top">PROBBIT RABBIT FEED</h5>
            <p>
              <a href="/probbit">Probbit Product Details</a><br>
              <a href="https://pronatural-storage.s3.ap-southeast-1.amazonaws.com/feedpro/downloadable/Probbit_Brochure_v2-051821.pdf" target="_blank">Probbit Feeding Program</a><br>
              <a href="https://youtube.com/playlist?list=PLl7pQqMM28bohSP9MnSqersq6B2GRlvRG">Probbit Videos</a>
            </p>
          </div>
          <div class="col-lg-3 col-md-3 col-sm-12 footer-div">
            <h5 class="footer-h5"><a href="/company">COMPANY</a></h5>
            <p>
              <a href="http://www.tateh.com/" target="_blank">Santeh Feeds</a><br>
              <a href="https://www.petonepetcare.com" target="_blank">Pet One Pet Care</a><br>
              <a href="http://www.fishtaseafood.com/" target="_blank">Fishta Seafood</a><br>
              <a href="http://infarmco.com/inpro/" target="_blank">Inpro Swine Genetics</a>
            </p>
            <h4><router-link :to="{ name: 'inquiry', hash: '#info-center' }">INFORMATION CENTERS</router-link></h4>
            <!-- <h4><a href="{{ url('alagang-pro') }}">ALAGANG PRO</a></h4>
            <h4><a href="{{ url('natural-meat-shops') }}">NATURAL MEAT SHOPS</a></h4>
            <h4><a href="{{ url('proavian') }}">PROAVIAN FEEDS</a></h4> -->
            <h4><router-link :to="{ name: 'inquiry', hash: '#inquiry-form' }">INQUIRE</router-link></h4>
            <h4><a href="/company/directory">COMPANY DIRECTORY</a></h4>
            <h4><a href="/company/logos">LOGOS</a></h4>
            <!-- <h4><a href="https://www.facebook.com/feedprofeeds/" target="_blank">FACEBOOK</a></h4>
            <h4><a href="https://www.youtube.com/channel/UCHYbopxCtlWm4hg4RgB4Yyg" target="_blank">YOUTUBE</a></h4> -->
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-center footer-row">
            <p>Copyright &copy; 2021 - Pronatural Feed Corporation</p>
          </div>
        </div>
      </footer>
    </div>
  </section>
</template>

<script>
import './assets/css/bootstrap.css';
import './assets/css/fonts/stylesheet.css';
import './assets/css/feedpro.css';
import './assets/css/responsive.css';
import { reactive, ref, watchEffect } from 'vue';
import router from './router';

export default {
  setup() {
    const state = reactive({
      root_url: process.env.VUE_APP_ENDPOINT,
      isHogsOpen: false,
      isPoultryOpen: false,
      isRabbitOpen: false
    });

    const isMenuOpen = ref(false);

    const openMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    }

    const closeMenu = () => {
      isMenuOpen.value = false;
    }

    const mobileMenu = (name) => {
      if(name == 'hogs') {
        state.isHogsOpen = !state.isHogsOpen;
        state.isPoultryOpen = false;
        state.isRabbitOpen = false;
      }
      if(name == 'poultry') {
        state.isPoultryOpen = !state.isPoultryOpen;
        state.isHogsOpen = false;
        state.isRabbitOpen = false;
      }
      if(name == 'rabbit') {
        state.isRabbitOpen = !state.isRabbitOpen;
        state.isHogsOpen = false;
        state.isPoultryOpen = false;
      }
    }

    const redirectRoute = (name) => {
      router.push({
        name: name
      });
    }

    const redirectMobileRoute = (name) => {
      closeMenu();
      if(name == 'inquiry') {
        router.push({
          name: name,
          query: {
            section: 'inquiry-form'
          }
        });
      } else {
        router.push({
          name: name
        });
      }
    }

    const openWindow = (url) => {
      window.open(url);
    }

    watchEffect(() => {
      document.body.classList.remove('overflow-y-hidden');
      if(isMenuOpen.value) {
        document.body.classList.add('overflow-y-hidden');
      }
    });

    return {
      state,
      isMenuOpen,
      openMenu,
      closeMenu,
      mobileMenu,
      redirectRoute,
      redirectMobileRoute,
      openWindow
    };
  },
  components: { router }
}
</script>

<style scoped>
.show {
  display: block !important;
}
</style>